var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [_c("a-spin", { attrs: { tip: "Loading..." } })],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol
              }
            },
            [
              _c(
                "a-form-model-item",
                { ref: "name", attrs: { label: "支店名", prop: "name" } },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.name.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "address", attrs: { label: "住所", prop: "address" } },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.address.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.address,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "comment", attrs: { label: "備考" } },
                [
                  _c("a-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.comment,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "comment", $$v)
                      },
                      expression: "form.comment"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "alcohol_check_type",
                  attrs: {
                    label: "アルコールチェックの使用",
                    prop: "alcohol_check_type"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.alcohol_check_type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "alcohol_check_type", $$v)
                        },
                        expression: "form.alcohol_check_type"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("使用しない")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("ドライバーが点呼完了処理")
                      ]),
                      _c("a-radio", { attrs: { value: 2 } }, [
                        _vm._v("管理者が点呼完了処理")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showRequiredAlcoholMeasurementResult === 1
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "is_required_alcohol_measurement_result",
                      attrs: {
                        label: "アルコール濃度の入力",
                        prop: "is_required_alcohol_measurement_result"
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value:
                              _vm.form.is_required_alcohol_measurement_result,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form,
                                "is_required_alcohol_measurement_result",
                                $$v
                              )
                            },
                            expression:
                              "form.is_required_alcohol_measurement_result"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("任意")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("必須")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  ref: "alcohol_check_default_allow_ride",
                  attrs: {
                    label: "[デフォルト値]アルコールチェック時の乗車可否",
                    prop: "alcohol_check_default_allow_ride"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.alcohol_check_default_allow_ride,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "alcohol_check_default_allow_ride",
                            $$v
                          )
                        },
                        expression: "form.alcohol_check_default_allow_ride"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("未選択")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("許可")]),
                      _c("a-radio", { attrs: { value: 2 } }, [_vm._v("不許可")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "alcohol_check_default_alcohol_detector",
                  attrs: {
                    label:
                      "[デフォルト値]アルコールチェック時のアルコール検知器",
                    prop: "alcohol_check_default_alcohol_detector"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.alcohol_check_default_alcohol_detector,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "alcohol_check_default_alcohol_detector",
                            $$v
                          )
                        },
                        expression:
                          "form.alcohol_check_default_alcohol_detector"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("未選択")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("有り")]),
                      _c("a-radio", { attrs: { value: 2 } }, [_vm._v("無し")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "alcohol_check_default_drinking",
                  attrs: {
                    label: "[デフォルト値]アルコールチェック時の酒気帯び",
                    prop: "alcohol_check_default_drinking"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.alcohol_check_default_drinking,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "alcohol_check_default_drinking",
                            $$v
                          )
                        },
                        expression: "form.alcohol_check_default_drinking"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("未選択")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("有り")]),
                      _c("a-radio", { attrs: { value: 2 } }, [_vm._v("無し")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "alcohol_check_default_roll_call_type",
                  attrs: {
                    label: "[デフォルト値]アルコールチェックの点呼方法",
                    prop: "alcohol_check_default_roll_call_type"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.alcohol_check_default_roll_call_type,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "alcohol_check_default_roll_call_type",
                            $$v
                          )
                        },
                        expression: "form.alcohol_check_default_roll_call_type"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("未選択")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("対面")]),
                      _c("a-radio", { attrs: { value: 2 } }, [
                        _vm._v("ビデオ通話")
                      ]),
                      _c("a-radio", { attrs: { value: 3 } }, [_vm._v("電話")]),
                      _c("a-radio", { attrs: { value: 4 } }, [_vm._v("その他")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.plusFlag === 1
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "use_inspection_flag",
                      attrs: {
                        label: "運行前点検の使用",
                        prop: "use_inspection_flag"
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.use_inspection_flag,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "use_inspection_flag", $$v)
                            },
                            expression: "form.use_inspection_flag"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("使用しない")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("使用する")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
                [
                  _c(
                    "a-button",
                    {
                      class: _vm.$style.filledBtn,
                      attrs: { type: "primary" },
                      on: { click: _vm.onSubmit }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.submitString) + "\n      "
                      )
                    ]
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: _vm.backPath } },
                    [
                      _c(
                        "a-button",
                        { staticStyle: { "margin-left": "10px" } },
                        [_vm._v("\n          戻る\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }